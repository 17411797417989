import type {SocialPost} from '@Components/social-media/post.vo';
import {PlatformMediaType, PostState} from '@Components/social-media/post.vo';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {SocialPostDialogCTA} from '@Panels/social-posts-dialog-panel/social-posts-dialog-panel.types';
import {
  convertDateToUTCTimestamp,
  deletePostSchedule,
  duplicatePostAsDraft,
  mapIndexToAccountPublishingParams,
  openSocialMediaPost,
  reAuthenticateInModal,
  schedulePost,
  viewPostOnSocialNetworkForAccountWithMediaType,
  viewPostOnSocialNetworks,
} from '@Libraries/social-media';
import type {SocialAccount} from '@Components/social-media/account.vo';
import {hasExpired, MetricsStatus} from '@Components/social-media/account.vo';
import {SocialPostUserGuidelines} from '@Components/social-media/components/social-post-dialog-metrics-grid/social-post-dialog-metrics-grid';
import {isUserPremium} from '@Libraries/user.library';

interface SocialPostDialogPanelState {
  currentPostState: PostState;
  post: SocialPost | null;
  currentScheduleDetails: Date;
  isDatePickerView: boolean;
  originalPostState: PostState;
  isRescheduled: boolean;
  currentAccountIndex: number;
  isErrorState: null | PostState.ERROR | PostState.SCHEDULE_ERROR; // stores error type / null if no error
  publishingStatus: PostState[];
  shouldShowBottomShadow: boolean;
  shouldShowTopShadow: boolean;
  userGuidelineType: SocialPostUserGuidelines;
}

export interface DialogCta {
  ctaType: SocialPostDialogCTA;
  post?: SocialPost;
}

const getCurrentDateForPicker = (): Date => {
  const newDate = new Date();

  if (newDate.getHours() === 23) {
    newDate.setHours(12, 0, 0, 0);
  } else {
    newDate.setHours(newDate.getHours() + 1, 0, 0, 0);
  }

  return newDate;
};

const initialState: SocialPostDialogPanelState = {
  originalPostState: PostState.PUBLISHED,
  post: null,
  currentPostState: PostState.PUBLISHED,
  currentScheduleDetails: getCurrentDateForPicker(),
  isDatePickerView: true,
  isRescheduled: false,
  currentAccountIndex: 0,
  isErrorState: null,
  publishingStatus: [],
  shouldShowTopShadow: false,
  shouldShowBottomShadow: false,
  userGuidelineType: SocialPostUserGuidelines.NO_GUIDELINES,
};

const onScheduleDatetime = (state: SocialPostDialogPanelState): void => {
  const date: Date = state.currentScheduleDetails;
  state.isDatePickerView = true;
  if (state.post) {
    state.post.publishOn = convertDateToUTCTimestamp(date);
    state.post.timeZoneOffset = date.getTimezoneOffset();
    const postObjectId = state.post.id;
    schedulePost(postObjectId, date);
  }
};

const onContinuePublishing = (state: SocialPostDialogPanelState): void => {
  if (state.post) {
    openSocialMediaPost(state.post.id);
  }
};
const onRefresh = (state: SocialPostDialogPanelState): void => {
  const {currentAccountIndex} = state;
  if (state.post) {
    const currentAccountId = state.post.accounts[currentAccountIndex === -1 ? 0 : currentAccountIndex].id;
    reAuthenticateInModal(currentAccountId);
  }
};

const onDuplicateDraft = (state: SocialPostDialogPanelState): void => {
  if (state.post) {
    duplicatePostAsDraft(state.post);
  }
};

const onSchedule = (state: SocialPostDialogPanelState): void => {
  state.currentPostState = PostState.SCHEDULED;
};

const onEditReschedule = (state: SocialPostDialogPanelState): void => {
  if (state.post) {
    openSocialMediaPost(state.post.id);
  }
};

const onViewPost = (state: SocialPostDialogPanelState): void => {
  const {currentAccountIndex} = state;

  if (state.post) {
    if (!isAccountSelected(state.currentAccountIndex)) {
      if (getNumPublishingParams(state.post) === 1) {
        const accountInformation = mapIndexToAccountPublishingParams(state.post, 0);
        viewPostOnSocialNetworkForAccountWithMediaType(state.post, accountInformation.account, accountInformation.platformMediaType);
      } else {
        viewPostOnSocialNetworks(state.post);
      }

      return;
    }

    const accountInformation = mapIndexToAccountPublishingParams(state.post, currentAccountIndex);
    const {account} = accountInformation;
    const {platformMediaType} = accountInformation;
    viewPostOnSocialNetworkForAccountWithMediaType(state.post, account, platformMediaType);
  }
};

const onDeleteSchedule = (state: SocialPostDialogPanelState): void => {
  if (state.post) {
    deletePostSchedule(state.post);
  }
};

const handleCta = (ctaObject: DialogCta, state: SocialPostDialogPanelState): void => {
  switch (ctaObject.ctaType) {
    case SocialPostDialogCTA.SCHEDULE_DATE_TIME:
      onScheduleDatetime(state);
      break;

    case SocialPostDialogCTA.CONTINUE_PUBLISHING:
      onContinuePublishing(state);
      break;

    case SocialPostDialogCTA.REFRESH:
      onRefresh(state);
      break;

    case SocialPostDialogCTA.DUPLICATE_DRAFT:
      onDuplicateDraft(state);
      break;

    case SocialPostDialogCTA.SCHEDULE:
      onSchedule(state);
      break;

    case SocialPostDialogCTA.EDIT_RESCHEDULE:
      onEditReschedule(state);
      break;

    case SocialPostDialogCTA.DELETE_SCHEDULE:
      onDeleteSchedule(state);
      break;

    case SocialPostDialogCTA.VIEW_POST:
      onViewPost(state);
      break;

    default:
      break;
  }
};

const isAccountSelected = (activePostIndex: number): boolean => {
  return activePostIndex !== -1;
};

const shouldGetError = (state: SocialPostDialogPanelState, activePostIndex: number): boolean => {
  const isAnyAccountSelected = isAccountSelected(activePostIndex);
  if (!isAnyAccountSelected && state.currentPostState !== PostState.ERROR && state.currentPostState !== PostState.SCHEDULE_ERROR) {
    return false;
  }

  return state.currentPostState === PostState.ERROR || state.currentPostState === PostState.SCHEDULE_ERROR || state.isErrorState !== null;
};

const shouldShowMetrics = (state: SocialPostDialogPanelState, activePostIndex: number): boolean => {
  if (!state.post) {
    return false;
  }

  if (!state.post.metrics) {
    return false;
  }
  const isAnyAccountSelected = isAccountSelected(activePostIndex);
  if (!isAnyAccountSelected) {
    return true;
  }

  const publishingInformation = mapIndexToAccountPublishingParams(state.post, activePostIndex);
  const {account} = publishingInformation;

  if (!account) {
    return false;
  }

  const accountId = account.id;
  const metricsForIthAccount = state.post.metrics[accountId];

  if (!metricsForIthAccount) {
    return false;
  }

  return true;
};

const getUserGuidelineByMetricStatus = (state: SocialPostDialogPanelState, activePostIndex: number): SocialPostUserGuidelines => {
  if (state.currentPostState !== PostState.PUBLISHED) {
    return SocialPostUserGuidelines.UNPUBLISHED_POST;
  }

  if (!state.post) {
    return SocialPostUserGuidelines.NO_GUIDELINES;
  }

  if (state.post.state === PostState.SCHEDULE_READY || state.post.state === PostState.DRAFT || state.post.state === PostState.READY) {
    return SocialPostUserGuidelines.UNPUBLISHED_POST;
  }

  const publishingInformation = mapIndexToAccountPublishingParams(state.post, activePostIndex);

  if (!publishingInformation.account || !publishingInformation.platformMediaType) {
    return SocialPostUserGuidelines.POPULATING;
  }

  if (publishingInformation.platformMediaType === PlatformMediaType.STORIES) {
    return SocialPostUserGuidelines.STORY_PERFORMANCE_LIMITATIONS;
  }

  if (publishingInformation.account.metricsStatus === MetricsStatus.AVAILABLE) {
    if (state.post.metrics && state.post.metrics[publishingInformation.account.id]) {
      return SocialPostUserGuidelines.NO_GUIDELINES;
    }

    return SocialPostUserGuidelines.POPULATING;
  }

  if (publishingInformation.account.metricsStatus === MetricsStatus.UNSUPPORTED) {
    return SocialPostUserGuidelines.PERFORMANCE_LIMITATIONS;
  }

  return SocialPostUserGuidelines.PERMISSION_PENDING;
};

const doUpdateUserGuidelineTypeIfNeeded = (state: SocialPostDialogPanelState, activePostIndex: number): void => {
  const isErrorType = shouldGetError(state, activePostIndex);
  const isAnyAccountSelected = isAccountSelected(activePostIndex);

  if (!isAnyAccountSelected) {
    state.userGuidelineType = getUserGuidelineForDefaultSelector(state);
    return;
  }

  if (!isUserPremium()) {
    state.userGuidelineType = getUserGuidelineForPremiumUsers(state, isErrorType);
    return;
  }

  if (!isErrorType && shouldShowMetrics(state, activePostIndex)) {
    state.userGuidelineType = SocialPostUserGuidelines.NO_GUIDELINES;
    return;
  }

  if (isErrorType) {
    state.userGuidelineType = SocialPostUserGuidelines.ERROR;
    return;
  }

  state.userGuidelineType = getUserGuidelineByMetricStatus(state, activePostIndex);
};

const getUserGuidelineForPremiumUsers = (state: SocialPostDialogPanelState, isErrorType: boolean): SocialPostUserGuidelines => {
  if (state.currentPostState === PostState.PUBLISHED && !isErrorType) {
    return SocialPostUserGuidelines.UPGRADE_PREMIUM;
  }

  if (isErrorType) {
    return SocialPostUserGuidelines.ERROR;
  }

  return SocialPostUserGuidelines.UNPUBLISHED_POST;
};

const areAllAccountsRequiringPermission = (accounts: SocialAccount[]): boolean => {
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].metricsStatus !== MetricsStatus.PERMISSION_PENDING) {
      return false;
    }
  }

  return true;
};

const areAllAccountsUnsupported = (accounts: SocialAccount[]): boolean => {
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].metricsStatus !== MetricsStatus.UNSUPPORTED) {
      return false;
    }
  }

  return true;
};

export const getNumPostTypePublishingParams = (socialPost: SocialPost): number => {
  let totalParams = 0;

  for (let i = 0; i < socialPost.accounts.length; i++) {
    const accountId = socialPost.accounts[i].id;
    const {publishingParams} = socialPost;
    const mediaTypePublishingParams = publishingParams[accountId];
    const postParams = mediaTypePublishingParams.POST;
    const reelParams = mediaTypePublishingParams.REELS;

    if (postParams) {
      totalParams += 1;
    }

    if (reelParams) {
      totalParams += 1;
    }
  }

  return totalParams;
};

const getNumStoryTypePublishingParams = (socialPost: SocialPost): number => {
  let totalParams = 0;

  for (let i = 0; i < socialPost.accounts.length; i++) {
    const accountId = socialPost.accounts[i].id;
    const {publishingParams} = socialPost;
    const mediaTypePublishingParams = publishingParams[accountId];
    const storyParams = mediaTypePublishingParams.STORIES;

    if (storyParams) {
      totalParams += 1;
    }
  }

  return totalParams;
};

export const getNumPublishingParams = (socialPost: SocialPost): number => {
  return getNumPostTypePublishingParams(socialPost) + getNumStoryTypePublishingParams(socialPost);
};

const getGuidelineTypeForDefaultSelectionPremium = (post: SocialPost): SocialPostUserGuidelines => {
  const doAllAccountsRequirePermission = areAllAccountsRequiringPermission(post.accounts);
  const areAccountsUnsupported = areAllAccountsUnsupported(post.accounts);
  const shouldShowPerformanceLimitation = areAccountsUnsupported && getNumPostTypePublishingParams(post) === 1;
  const areAllStories = getNumStoryTypePublishingParams(post) === getNumPublishingParams(post);
  const isOnlyOneStory = areAllStories && getNumStoryTypePublishingParams(post) === 1;

  if (areAllStories || isOnlyOneStory) {
    return SocialPostUserGuidelines.STORY_PERFORMANCE_LIMITATIONS;
  }

  if (shouldShowPerformanceLimitation) {
    return SocialPostUserGuidelines.PERFORMANCE_LIMITATIONS;
  }

  if (doAllAccountsRequirePermission) {
    return SocialPostUserGuidelines.PERMISSION_PENDING;
  }

  if (!post.metrics) {
    return SocialPostUserGuidelines.POPULATING;
  }

  if (Object.keys(post.metrics).length === 0) {
    return SocialPostUserGuidelines.POPULATING;
  }

  return SocialPostUserGuidelines.NO_GUIDELINES;
};

const getUserGuidelineForDefaultSelector = (state: SocialPostDialogPanelState): SocialPostUserGuidelines => {
  if (state.currentPostState === PostState.PUBLISHED) {
    if (!isUserPremium()) {
      return SocialPostUserGuidelines.UPGRADE_PREMIUM;
    }

    if (!state.post) {
      return SocialPostUserGuidelines.NO_GUIDELINES;
    }

    return getGuidelineTypeForDefaultSelectionPremium(state.post);
  }

  return SocialPostUserGuidelines.UNPUBLISHED_POST;
};

const getStateIfReady = (post: SocialPost): PostState.SCHEDULE_READY | PostState.DRAFT => {
  if (post.timeZoneOffset === null) {
    return PostState.DRAFT;
  }

  return PostState.SCHEDULE_READY;
};

const getErrorType = (socialPost: SocialPost, activePostIndex: number): PostState.SCHEDULE_ERROR | PostState.ERROR => {
  const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, activePostIndex);
  const {account} = publishingParamsInformation;
  const platformParams = publishingParamsInformation.platformMediaType;

  if (!account || !platformParams) {
    return PostState.ERROR;
  }

  if (!socialPost.publishingParams[account.id]) {
    return PostState.ERROR;
  }

  const platformPublishingParams = socialPost.publishingParams[account.id][platformParams];

  if (!platformPublishingParams) {
    return PostState.ERROR;
  }

  if (platformPublishingParams.state === PostState.SCHEDULE_ERROR) {
    return PostState.SCHEDULE_ERROR;
  }

  return PostState.ERROR;
};

const doesAccountHaveMetrics = (post: SocialPost, currentAccount: SocialAccount): boolean => {
  const postMetrics = post.metrics;
  let areMetricsSet = false;

  if (postMetrics && typeof postMetrics[currentAccount.id] !== 'undefined') {
    areMetricsSet = true;
  }

  return areMetricsSet;
};

const doUpdateErrorStateIfNeeded = (state: SocialPostDialogPanelState, activePostIndex: number): void => {
  const isAnyAccountSelected = isAccountSelected(activePostIndex);

  if (!isAnyAccountSelected) {
    if (state.originalPostState === PostState.SCHEDULE_ERROR || state.originalPostState === PostState.ERROR) {
      state.isErrorState = state.originalPostState;
      return;
    }

    state.isErrorState = null;
    return;
  }

  if (!state.post) {
    return;
  }

  const publishingParamsInformation = mapIndexToAccountPublishingParams(state.post, activePostIndex);

  if (!publishingParamsInformation.account) {
    return;
  }

  const isAccountExpired = hasExpired(publishingParamsInformation.account.state);

  if (isAccountExpired && !doesAccountHaveMetrics(state.post, publishingParamsInformation.account)) {
    state.isErrorState = PostState.ERROR;
  } else if (isCurrentPlatformErroneous(state.post, activePostIndex) || state.currentPostState === PostState.ERROR) {
    state.isErrorState = getErrorType(state.post, activePostIndex);
  } else {
    state.isErrorState = null;
  }
};

const isCurrentPlatformErroneous = (socialPost: SocialPost, currentActiveIndex: number): boolean => {
  const publishingParamsInformation = mapIndexToAccountPublishingParams(socialPost, currentActiveIndex);
  const {account} = publishingParamsInformation;
  const platformParams = publishingParamsInformation.platformMediaType;

  if (!account || !platformParams) {
    return true;
  }

  if (!socialPost.publishingParams[account.id]) {
    return true;
  }

  const platformPublishingParams = socialPost.publishingParams[account.id][platformParams];

  if (!platformPublishingParams) {
    return true;
  }

  return platformPublishingParams.state === PostState.ERROR || platformPublishingParams.state === PostState.SCHEDULE_ERROR;
};

export const socialPostDialogPanelSlice = createSlice({
  name: 'socialPostDialog',
  initialState,
  reducers: {
    setSocialPostDialogStatus: (state, action: PayloadAction<PostState>): void => {
      state.currentPostState = action.payload;
    },

    setIsRescheduled: (state, action: PayloadAction<boolean>): void => {
      state.isRescheduled = action.payload;
    },

    setInitialStates: (state, action: PayloadAction<SocialPost>): void => {
      if (action.payload.state === PostState.READY) {
        const stateIfReady = getStateIfReady(action.payload);
        state.originalPostState = stateIfReady;
        state.currentPostState = stateIfReady;
      } else {
        state.currentPostState = action.payload.state;
        state.originalPostState = action.payload.state;
      }

      state.post = action.payload;
      state.currentAccountIndex = -1;
      state.isRescheduled = false;
      state.publishingStatus = [];
      state.shouldShowBottomShadow = false;
      state.shouldShowTopShadow = false;
      state.userGuidelineType = getUserGuidelineForDefaultSelector(state);
      doUpdateErrorStateIfNeeded(state, -1);
    },

    setCurrentScheduleDetails: (state, action: PayloadAction<any>): void => {
      state.currentScheduleDetails = action.payload;
    },

    setIsDatePickerView: (state, action: PayloadAction<boolean>): void => {
      state.isDatePickerView = action.payload;
    },

    resetState: (state): void => {
      state.currentPostState = state.originalPostState;
      state.currentScheduleDetails = getCurrentDateForPicker();
      state.isDatePickerView = true;
      state.currentAccountIndex = -1;
      state.shouldShowBottomShadow = false;
      state.shouldShowTopShadow = false;
      state.userGuidelineType = SocialPostUserGuidelines.NO_GUIDELINES;
    },

    onDialogCta: (state, action: PayloadAction<DialogCta>): void => {
      handleCta(action.payload, state);
    },

    setCurrentAccountIndex: (state, action: PayloadAction<number>): void => {
      state.currentAccountIndex = action.payload;
      doUpdateErrorStateIfNeeded(state, action.payload);
      doUpdateUserGuidelineTypeIfNeeded(state, action.payload);
    },
    setTopShadow: (state, action: PayloadAction<boolean>): void => {
      state.shouldShowTopShadow = action.payload;
    },
    setBottomShadow: (state, action: PayloadAction<boolean>): void => {
      state.shouldShowBottomShadow = action.payload;
    },
  },
});

export const {
  setSocialPostDialogStatus,
  setIsDatePickerView,
  setCurrentScheduleDetails,
  resetState,
  onDialogCta,
  setInitialStates,
  setIsRescheduled,
  setCurrentAccountIndex,
  setTopShadow,
  setBottomShadow,
} = socialPostDialogPanelSlice.actions;

export const socialPostDialogReducer = socialPostDialogPanelSlice.reducer;
