import type {StoryGuideline} from '@Components/social-media-wizard/components/social-media-publish-params-story-guideline';

export const WizardNavHeight = 56;
export const WizardMobileFooterHeight = 64;

export const WizardMobilePaddingOffsets = 16;

/**
 * For the mobile version, a single preview being displayed should never have a background that scrolls
 * and should be just spanning over the available screen space
 */
export const getMobilePreviewWindowHeight = (): number => {
  return window.innerHeight - (WizardNavHeight + WizardMobileFooterHeight + WizardMobilePaddingOffsets);
};

export const storyGuidelinesData: StoryGuideline[] = [
  {
    emoji: `🛸`,
    text: `pmwjs_story_caption_guideline_one`,
  },
  {
    emoji: `🤠`,
    text: `pmwjs_story_caption_guideline_two`,
  },
  {
    emoji: `🦄`,
    text: `pmwjs_story_caption_guideline_three`,
  },
  {
    emoji: `🍄`,
    text: `pmwjs_story_caption_guideline_four`,
  },
  {
    emoji: `🍕`,
    text: `pmwjs_story_caption_guideline_five`,
  },
  {
    emoji: `✋`,
    text: `pmwjs_story_caption_guideline_six`,
  },
  {
    emoji: `💭`,
    text: `pmwjs_story_caption_guideline_seven`,
  },
  {
    emoji: `🍁`,
    text: `pmwjs_story_caption_guideline_eight`,
  },
  {
    emoji: `🎂`,
    text: `pmwjs_story_caption_guideline_nine`,
  },
  {
    emoji: `🐾`,
    text: `pmwjs_story_caption_guideline_ten`,
  },
  {
    emoji: `🚗`,
    text: `pmwjs_story_caption_guideline_eleven`,
  },
];

const getRandomNumberInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomNumberForStoryCaptionGuideline = (): number => {
  return getRandomNumberInRange(0, storyGuidelinesData.length - 1);
};

export const getStoryGuidelineforIndex = (index: number): StoryGuideline => {
  return storyGuidelinesData[index];
};

export const getUnusedRandomGuidelineNumber = (alreadyUsedRandomNumbers: number[]): number => {
  const maxNumRetries = 150;
  let retryCounter = 0;
  while (retryCounter < maxNumRetries) {
    const generatedNumber = getRandomNumberForStoryCaptionGuideline();
    const indexFound = alreadyUsedRandomNumbers.indexOf(generatedNumber);

    retryCounter += 1;

    if (indexFound === -1) {
      return generatedNumber;
    }
  }

  return 0;
};
