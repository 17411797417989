import {createSlice} from '@reduxjs/toolkit';
import type {
  AutoCaptionReduxStateType,
  CaptionType,
  InAutoCaptionModeType,
  OneOf,
  PromptConfigOptions,
  RecentCaptionType,
  SetSelectedCaptionActionType,
} from '@Components/auto-caption-prompt-box/auto-caption.types';
import {getRecentlyUsedAICaptionForUser} from '@Components/auto-caption-prompt-box/auto-caption-thunk';

const initialState: AutoCaptionReduxStateType = {
  inAutoCaptionMode: {accountId: null},
  prompt: '',
  selectedCaption: '',
  isError: false,
  errorMessage: '',
  recentCaptions: [],
  generatedCaptions: [],
  hasSeenIntroPopup: false,
  hasSeenReminderPopup: false,
  alterCaptionOptions: [],
  previouslyUsedOptionsForAlteringCaption: null,
  loadingStates: {
    isLoadingGenerate: false,
    isLoadingUseCaption: false,
    isLoadingAlterCaption: false,
    isLoadingRecents: false,
  },
};

const autoCaptionSlice = createSlice({
  name: 'AutoCaption',
  initialState,
  reducers: {
    setInAutoCaptionMode: (state, action: {payload: InAutoCaptionModeType}) => {
      state.inAutoCaptionMode = action.payload;
    },
    setPrompt: (state, action: {payload: string}) => {
      state.prompt = action.payload;
    },
    setSelectedCaption: {
      reducer: (state: AutoCaptionReduxStateType, action: SetSelectedCaptionActionType) => {
        const {caption} = action.payload;
        state.selectedCaption = caption;
      },
      prepare: (caption: string): SetSelectedCaptionActionType => {
        return {payload: {caption}};
      },
    },
    setErrorMessage: (state, action: {payload: string}) => {
      state.errorMessage = action.payload;
    },
    setIsError: (state, action: {payload: boolean}) => {
      state.isError = action.payload;
    },
    setGenerateLoadingState: (state, action: {payload: boolean}) => {
      state.loadingStates.isLoadingGenerate = action.payload;
    },
    setUseCaptionLoadingState: (state, action: {payload: boolean}) => {
      state.loadingStates.isLoadingUseCaption = action.payload;
    },
    setRecentsLoadingState: (state, action: {payload: boolean}) => {
      state.loadingStates.isLoadingRecents = action.payload;
    },
    setAlterCaptionLoadingState: (state, action: {payload: boolean}) => {
      state.loadingStates.isLoadingAlterCaption = action.payload;
    },
    setRecentCaptions: (state, action: {payload: RecentCaptionType[]}) => {
      state.recentCaptions = action.payload;
    },
    setGeneratedCaptions: (state, action: {payload: CaptionType[]}) => {
      state.generatedCaptions = action.payload;
    },
    setHasSeenIntroPopup: (state, action: {payload: boolean}) => {
      state.hasSeenIntroPopup = action.payload;
    },
    setHasSeenReminderPopup: (state, action: {payload: boolean}) => {
      state.hasSeenReminderPopup = action.payload;
    },
    setAlterCaptionOptions: (state, action: {payload: OneOf<PromptConfigOptions>[]}) => {
      state.alterCaptionOptions = action.payload;
    },
    setPreviouslyUsedOptionsForAlterCaption: (state, action: {payload: PromptConfigOptions}) => {
      state.previouslyUsedOptionsForAlteringCaption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentlyUsedAICaptionForUser.fulfilled, (state, action) => {
      state.recentCaptions = action.payload;
    });
  },
});

export const {
  setSelectedCaption,
  setErrorMessage,
  setIsError,
  setInAutoCaptionMode,
  setPrompt,
  setUseCaptionLoadingState,
  setGenerateLoadingState,
  setRecentsLoadingState,
  setAlterCaptionLoadingState,
  setRecentCaptions,
  setHasSeenIntroPopup,
  setGeneratedCaptions,
  setAlterCaptionOptions,
  setPreviouslyUsedOptionsForAlterCaption,
  setHasSeenReminderPopup,
} = autoCaptionSlice.actions;

export const autoCaptionReducer = autoCaptionSlice.reducer;
