import type {SocialAccount} from '@Components/social-media/account.vo';
import {isFacebookAccount, isInstagramAccount} from '@Components/social-media/account.vo';
import {PILL_THEME} from '@Components/pill';
import {formatLocalDate} from '@Utils/date.util';
import type {SocialMediaPublishingParams, PosterHashedId} from '@Components/social-media/publish-params.types';
import type {SocialPlatformMetrics} from '@Components/social-media/social-platform-metrics.types';
import type {LoadingStates} from '@Utils/loading.util';

export type SocialMediaAccountId = number;
export type SocialMediaAccountIdString = string;
export type MetaMediaType = InstagramMediaType | FacebookMediaType;

export type TikTokPrivacySettings = TikTokPublicAccountPrivacySettings | TikTokPrivateAccountPrivacySettings;
export type MediaTypePublishingParams = Record<PlatformMediaType, SocialMediaPublishingParams | null>;
export type MediaTypeLoadingStates = Record<PlatformMediaType, LoadingStates>;

export enum TikTokPublicAccountPrivacySettings {
  PUBLIC_TO_EVERYONE = 'PUBLIC_TO_EVERYONE',
  MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
  SELF_ONLY = 'SELF_ONLY',
}

export enum TikTokPrivateAccountPrivacySettings {
  FOLLOWER_OF_CREATOR = 'FOLLOWER_OF_CREATOR',
  MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
  SELF_ONLY = 'SELF_ONLY',
}

export enum LinkedInPrivacySettings {
  PUBLIC = 'PUBLIC',
  CONNECTIONS = 'CONNECTIONS',
  LOGGED_IN = 'LOGGED_IN',
}

export enum YouTubePrivacySettings {
  PUBLIC = 'PUBLIC',
  UNLISTED = 'UNLISTED',
  PRIVATE = 'PRIVATE',
}

export enum PrivacySettings {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum InstagramMediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  REELS = 'REELS',
  STORIES = 'STORIES',
}

export enum FacebookMediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  REELS = 'REELS',
  STORIES = 'STORIES',
}

export enum PlatformMediaType {
  POST = 'POST',
  STORIES = 'STORIES',
  REELS = 'REELS',
}

export enum GoogleBusinessProfileCtaType {
  BOOK = 'BOOK',
  ORDER = 'ORDER',
  SHOP = 'SHOP',
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  CALL = 'CALL',
}

export enum GoogleBusinessProfilePostType {
  STANDARD = 'STANDARD',
  EVENT = 'EVENT',
  OFFER = 'OFFER',
}

export enum PostState {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SCHEDULE_READY = 'schedule_ready',
  PUBLISHING = 'publishing',
  READY = 'ready',
  PUBLISHED = 'published',
  ERROR = 'error',
  SCHEDULE_ERROR = 'schedule_error',
  DELETED = 'deleted',
  WAITING_FOR_INSTAGRAM = 'waiting_for_instagram',
  SCHEDULING = 'scheduling',
}

export interface SocialPost {
  id: string;
  caption?: string;
  title?: string;
  userId: number;
  privacySetting: PrivacySettings;
  state: PostState;
  createdOn?: number;
  publishingParams: Record<SocialMediaAccountIdString, MediaTypePublishingParams>;
  metrics?: Record<SocialMediaAccountIdString, SocialPlatformMetrics>;
  lastModified?: number;
  publishOn?: number;
  timeZoneOffset?: number | null;
  metricsUpdatedOn?: number | null;
  accounts: SocialAccount[];
  posterHashedId: string;
  isDeleted: boolean;
  isVideo: boolean;
  posterHashedIds: PosterHashedId[];
  areMetricsProcessed: boolean;
}

export const getPillThemeByState = (state: PostState): PILL_THEME => {
  switch (state) {
    case PostState.SCHEDULED:
    case PostState.SCHEDULE_READY:
    case PostState.PUBLISHING:
    case PostState.READY:
    case PostState.WAITING_FOR_INSTAGRAM:
    case PostState.SCHEDULING:
      return PILL_THEME.PRIMARY;

    case PostState.PUBLISHED:
      return PILL_THEME.SUCCESS;

    case PostState.ERROR:
    case PostState.SCHEDULE_ERROR:
      return PILL_THEME.DANGER;
    case PostState.DELETED:
      return PILL_THEME.NEUTRAL;

    case PostState.DRAFT:
    default:
      return PILL_THEME.NEUTRAL;
  }
};

export const getReadablePrivacySetting = (privacySetting: PrivacySettings): string => {
  switch (privacySetting) {
    case PrivacySettings.PRIVATE:
      return window.i18next.t('pmwjs_private');
    case PrivacySettings.PUBLIC:
      return window.i18next.t('pmwjs_public');
    default:
      return '';
  }
};

export const getReadableTikTokPrivacySetting = (privacySetting: TikTokPrivacySettings): string => {
  switch (privacySetting) {
    case TikTokPublicAccountPrivacySettings.PUBLIC_TO_EVERYONE:
      return window.i18next.t('pmwjs_everyone');
    case TikTokPrivateAccountPrivacySettings.FOLLOWER_OF_CREATOR:
      return window.i18next.t('pmwjs_followers');
    case TikTokPrivateAccountPrivacySettings.MUTUAL_FOLLOW_FRIENDS:
      return window.i18next.t('pmwjs_friends');
    case TikTokPrivateAccountPrivacySettings.SELF_ONLY:
      return window.i18next.t('pmwjs_only_you');
    default:
      return '';
  }
};

export const getReadableYouTubePrivacySetting = (privacySetting: YouTubePrivacySettings): string => {
  switch (privacySetting) {
    case YouTubePrivacySettings.PUBLIC:
      return window.i18next.t('pmwjs_public');
    case YouTubePrivacySettings.UNLISTED:
      return window.i18next.t('pmwjs_unlisted');
    case YouTubePrivacySettings.PRIVATE:
      return window.i18next.t('pmwjs_private');

    default:
      return '';
  }
};

export const getReadableLinkedInPrivacySetting = (privacySetting: LinkedInPrivacySettings): string => {
  switch (privacySetting) {
    case LinkedInPrivacySettings.PUBLIC:
      return window.i18next.t('pmwjs_public');
    case LinkedInPrivacySettings.CONNECTIONS:
      return window.i18next.t('pmwjs_connections');
    case LinkedInPrivacySettings.LOGGED_IN:
      return window.i18next.t('pmwjs_logged_in');

    default:
      return '';
  }
};

export const getReadableState = (state: PostState): string => {
  switch (state) {
    case PostState.SCHEDULED:
    case PostState.SCHEDULE_READY:
      return window.i18next.t('pmwjs_scheduled');

    case PostState.PUBLISHING:
    case PostState.WAITING_FOR_INSTAGRAM:
      return window.i18next.t('pmwjs_publishing_no_dots');

    case PostState.SCHEDULING:
      return window.i18next.t('pmwjs_scheduling');

    case PostState.READY:
      return window.i18next.t('pmwjs_ready_to_publish');
    case PostState.PUBLISHED:
      return window.i18next.t('pmwjs_published');

    case PostState.ERROR:
    case PostState.SCHEDULE_ERROR:
      return window.i18next.t('pmwjs_error');
    case PostState.DELETED:
      return window.i18next.t('pmwjs_deleted');

    case PostState.DRAFT:
    default:
      return window.i18next.t('pmwjs_draft');
  }
};

export const getReadableDate = (post: SocialPost): string => {
  if (post.state === PostState.DRAFT || !post.publishOn) {
    return window.i18next.t('pmwjs_none_draft');
  }

  return formatLocalDate(post.publishOn);
};

export const getMonthFromReadableDate = (readableDate: string): string => {
  const dateComponents = readableDate.split(', ');
  const monthDay = dateComponents[0]?.split(' ') || [''];
  return monthDay[0] || '';
};

export const getDayFromReadableDate = (readableDate: string): string => {
  const dateComponents = readableDate.split(', ');
  const monthDay = dateComponents[0]?.split(' ') || [''];
  return monthDay[1] || '';
};

export const getYearFromReadableDate = (readableDate: string): string => {
  const dateComponents = readableDate.split(', ');
  return dateComponents[1] || '';
};

export const getTimeFromReadableDate = (readableDate: string): string => {
  const dateComponents = readableDate.split(', ');
  return dateComponents[2] || '';
};

export const isReelType = (account: SocialAccount, publishingParams: SocialMediaPublishingParams, posterHashedId: string): boolean => {
  const graphicsMetaForPosterHashedId = publishingParams.graphicsMeta[posterHashedId];
  const isFacebookReel = 'mediaType' in graphicsMetaForPosterHashedId && isFacebookAccount(account) && graphicsMetaForPosterHashedId.mediaType === FacebookMediaType.REELS;
  const isInstagramReel = 'mediaType' in graphicsMetaForPosterHashedId && isInstagramAccount(account) && graphicsMetaForPosterHashedId.mediaType === InstagramMediaType.REELS;
  return isFacebookReel || isInstagramReel;
};
