import type {
  CaptionType,
  ConfigOptionListIDsType,
  HasSeenReponse,
  OneOf,
  PromptConfigOptions,
  RecentCaptionType,
  ReuseCaptionResponse,
} from '@Components/auto-caption-prompt-box/auto-caption.types';
import {AutoCaptionOptionType, DEFAULT_GENERATION_CONFIG_OPTIONS, HashtagUsage, LengthCategory, PopupType, Tone} from '@Components/auto-caption-prompt-box/auto-caption.types';
import type {InputType} from '@Components/input-field-v2/input-field.types';
import {getEnumFromValue} from '@Utils/types.util';
import type {ListItemStorage} from '@Components/list/list.types';
import type {PromptBoxAlterationLoadingText, PromptBoxTitles} from '@Components/ai-text-prompt-box/ai-text-prompt-box.types';

/**
 * @author: Moeed Ahmad <moeed@250mils.com>
 * */
export const AI_CAPTION_INTRO_POPUP_SEEN_CACHE_KEY = 'ai_caption_intro_popup_seen_';
const AI_CAPTIONS_API_PATH = 'autocaptions';

export const getUserHasSeenAiCaptionPopupsStatus = async (): Promise<HasSeenReponse> => {
  const popupsHasSeenStatusInCache = getCachedUserHasSeenAiCaptionPopups();

  if (popupsHasSeenStatusInCache) {
    return popupsHasSeenStatusInCache;
  }

  try {
    const response: HasSeenReponse = (await window.PMW.readLocal(`${AI_CAPTIONS_API_PATH}/hasSeenIntroPopup`)) as HasSeenReponse;

    if (response) {
      window.PMW.setCacheItem(getAiCaptionPopupsSeenCacheKey(), JSON.stringify(response));
    }

    return response;
  } catch (error) {
    console.error(error);
  }

  return hasSeenStatusDefaultFalse;
};

export const markUserHasSeenPopup = async (popupType: PopupType): Promise<HasSeenReponse> => {
  try {
    const hasSeenResponse = (await window.PMW.writeLocal(`${AI_CAPTIONS_API_PATH}/markHasSeenIntroPopup`, {popupType})) as HasSeenReponse;
    window.PMW.setCacheItem(getAiCaptionPopupsSeenCacheKey(), JSON.stringify(hasSeenResponse));
    return hasSeenResponse;
  } catch (error) {
    console.error(error);
    return hasSeenStatusDefaultFalse;
  }
};

export const requestAlterCaption = async (caption: CaptionType, propertiesToAlter: OneOf<PromptConfigOptions>[], accountId: number, postId: string): Promise<CaptionType> => {
  return (await window.PMW.readLocal(`${AI_CAPTIONS_API_PATH}/alter`, {
    caption,
    propertiesToAlter,
    accountId,
    postId,
  })) as CaptionType;
};

export const requestGenerateCaptions = async (
  prompt: InputType,
  options: PromptConfigOptions,
  accountId: number,
  postId: string,
  generateIdeasOriginator: GenerateIdeasOriginator
): Promise<CaptionType[]> => {
  return (await window.PMW.readLocal(`${AI_CAPTIONS_API_PATH}/generate`, {
    accountId,
    prompt,
    hasHashtags: hasHashtags(options),
    tone: options.tone,
    lengthCategory: options.lengthCategory,
    postId,
    generateIdeasOriginator,
  })) as CaptionType[];
};

export const requestUseCaption = async (caption: CaptionType, prompt: InputType, options: PromptConfigOptions, accountId: number | null, postId: string): Promise<void> => {
  await window.PMW.writeLocal(`${AI_CAPTIONS_API_PATH}/useCaption`, {
    caption,
    prompt,
    tone: options.tone,
    lengthCategory: options.lengthCategory,
    accountId,
    postId,
  });
};

export const requestReuseCaption = async (captionId: string): Promise<ReuseCaptionResponse> => {
  const response = (await window.PMW.writeLocal(`${AI_CAPTIONS_API_PATH}/reuseCaption`, {
    captionId,
  })) as ReuseCaptionResponse;

  return {
    ...response,
    options: {
      lengthCategory: getEnumFromValue(response.options.lengthCategory, LengthCategory) ?? DEFAULT_GENERATION_CONFIG_OPTIONS.lengthCategory,
      tone: getEnumFromValue(response.options.tone, Tone) ?? DEFAULT_GENERATION_CONFIG_OPTIONS.tone,
      hasHashtags: response.options.hasHashtags ? HashtagUsage.USE_HASHTAGS : HashtagUsage.NO_HASHTAGS,
    },
  };
};

export const requestRecentCaptions = async (): Promise<RecentCaptionType[]> => {
  return (await window.PMW.readLocal(`${AI_CAPTIONS_API_PATH}/getRecent`)) as RecentCaptionType[];
};

export const requestResetAiCaptionInsights = async (postId: string | null, accountIds: number[] | null): Promise<void> => {
  await window.PMW.readLocal(`${AI_CAPTIONS_API_PATH}/resetInsights`, {
    postId,
    accountIds,
  });
};

const hasHashtags = (options: PromptConfigOptions): boolean => {
  return options.hasHashtags === HashtagUsage.USE_HASHTAGS;
};

const getAiCaptionPopupsSeenCacheKey = (): string => {
  return AI_CAPTION_INTRO_POPUP_SEEN_CACHE_KEY + window.PMW.getUserId();
};

const getCachedUserHasSeenAiCaptionPopups = (): HasSeenReponse | null => {
  const cachedHasSeenStatus = JSON.parse(window.PMW.getCacheItem(getAiCaptionPopupsSeenCacheKey()) as string) as HasSeenReponse;

  if (!cachedHasSeenStatus) {
    return null;
  }

  return {
    [PopupType.INTRO]: cachedHasSeenStatus[PopupType.INTRO] ?? false,
    [PopupType.REMINDER]: cachedHasSeenStatus[PopupType.REMINDER] ?? false,
  };
};

export const isRecentCaption = (caption: CaptionType | RecentCaptionType): caption is RecentCaptionType => {
  return (caption as RecentCaptionType).captionid !== undefined;
};

export const getSelectedValue = (selectedOptions: ListItemStorage[] | undefined, defaultValue: LengthCategory | Tone | HashtagUsage): string => {
  return selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].id.split('-')[0] : defaultValue;
};

export const getListIdsForConfigOptions = (uniqueId: string): ConfigOptionListIDsType => {
  return {
    ID_LENGTH_LIST: `${uniqueId}-list-${AutoCaptionOptionType.LENGTH}`,
    ID_TONE_LIST: `${uniqueId}-list-${AutoCaptionOptionType.TONE}`,
    ID_HASHTAG_USAGE_LIST: `${uniqueId}-list-${AutoCaptionOptionType.HASHTAGS}`,
  };
};

/**
 * based on Operations enum in Operations.php for AutoCaptions controller
 * */
export enum GenerateIdeasOriginator {
  Generate = 'generate',
  MoreIdeas = 'more_ideas',
}

export const hasSeenStatusDefaultFalse: HasSeenReponse = {
  [PopupType.INTRO]: false,
  [PopupType.REMINDER]: false,
};

export const getPromptBoxTitles = (isInMobileMode: boolean = false): PromptBoxTitles => {
  return {
    selected: 'pmwjs_selected_caption',
    recents: isInMobileMode ? 'pmwjs_history' : 'pmwjs_recently_used_captions',
    main: isInMobileMode ? 'pmwjs_caption_ideas' : 'pmwjs_ai_captions',
    primarySelectedContent: 'pmwjs_caption',
  };
};

export const getAlterationLoadingText = (): PromptBoxAlterationLoadingText => {
  return {
    primaryAlterationLoadingMessage: 'pmwjs_updating_caption',
  };
};
