import {createContext} from 'react';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {SocialMediaWizardContainerQuery} from './social-media-wizard.types';

export const SocialMediaWizardContainerQueriesContext = createContext<SocialMediaWizardContainerQuery>({
  isExtraSmallScreen: undefined,
  isSmallScreen: undefined,
  isMediumScreen: undefined,
  isLargeScreen: undefined,
  isExtraLargeScreen: undefined,
});

export const containerQuery: ContainerQueryObject = {
  xsmall: {
    minWidth: 0,
    maxWidth: 480,
  },
  small: {
    minWidth: 481,
    maxWidth: 783,
  },
  medium: {
    minWidth: 784,
    maxWidth: 900,
  },
  large: {
    minWidth: 901,
    maxWidth: 1149,
  },
  xlarge: {
    minWidth: 1150,
  },
};
