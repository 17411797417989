import type {
  ContainerQueryParams,
  SocialMediaWizardContainerQuery,
  SocialMediaWizardMediaTypeSelection,
  WizardMobileNavView,
} from '@Components/social-media-wizard/social-media-wizard.types';
import {SocialMediaWizardStep} from '@Components/social-media-wizard/social-media-wizard.types';
import {useRef} from 'react';
import {useContainerQuery} from '@Hooks/useContainerQuery';
import {containerQuery} from '@Components/social-media-wizard/social-media-wizard-container-queries-context';
import type {SocialAccount} from '@Components/social-media/account.vo';
import type {GraphicMeta, PosterHashedId, SocialPostPublishingAction} from '@Components/social-media/publish-params.types';
import type {
  PosterNeedsResizeInformation,
  AccountNeedsResizeMapForDesigns,
  AccountPublishableUrlsForMediaType,
  AccountResizeStatusForMediaType,
  CaptionErrorByAccountIds,
  TitleErrorByAccountIds,
} from '@Components/social-media/social-media.types';
import {PosterResizeRequiredStatus} from '@Components/social-media/social-media.types';
import {usePublishingStatusForAllAccounts} from '@Components/social-media/social-media.hooks';
import {LoadingStates} from '@Utils/loading.util';
import type {MediaTypePublishingParams, SocialMediaAccountId} from '@Components/social-media/post.vo';
import {PlatformMediaType} from '@Components/social-media/post.vo';
import {AccountResizeStatus} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import type {ErrorBoxInputs} from 'src/components/error-box';
import type {DesignSelectorTab} from '@Components/design-selector/design-selector.types';
import {getNonStoryPlatformMediaType} from '@Libraries/social-media';
import {useAppSelector} from '@/hooks';

export const useSocialMediaWizardContainerQuery = (): ContainerQueryParams => {
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useContainerQuery(containerRef, containerQuery);

  const containerQueryParamProps: SocialMediaWizardContainerQuery = {
    isExtraSmallScreen: params.xsmall,
    isSmallScreen: params.small,
    isMediumScreen: params.medium,
    isLargeScreen: params.large,
    isExtraLargeScreen: params.xlarge,
  };

  return {
    containerQueryParamProps,
    containerRef,
    hasContainerQueryInitialized: params.small !== undefined,
  };
};

export const useWizardMobileNavView = (): WizardMobileNavView => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.wizardMobileNavView;
  });
};

export const useHasSeenConfetti = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.hasSeenConfetti;
  });
};

export const usePlatformSelectionforAccount = (accountId: number): SocialMediaWizardMediaTypeSelection => {
  return useAppSelector((state): SocialMediaWizardMediaTypeSelection => {
    return state.socialMediaWizard.platformSelectionForAccount[accountId];
  });
};

export const useIsTemplateSelected = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.isTemplateSelected;
  });
};

export const useIsSocialMediaWizardAiCaptionsEnabled = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.isSocialMediaWizardAiCaptionsEnabled;
  });
};

export const useIsGoogleBusinessProfileEnabled = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.isGoogleBusinessProfileEnabled;
  });
};

export const useAreAccountsLoading = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.areAccountsLoading;
  });
};

export const useHasPublishingFinished = (): boolean => {
  const publishingStatuses = usePublishingStatusForAllAccounts();

  return useAppSelector((state) => {
    if (state.socialMediaWizard.wizardStep !== SocialMediaWizardStep.PUBLISH) {
      return false;
    }

    const accounts = state.socialMediaWizard.accountIdsToPublishTo;

    for (let i = 0; i < accounts.length; i++) {
      const publishingStatusesForAccount = publishingStatuses[accounts[i]];
      const isPostLoaded = publishingStatusesForAccount[PlatformMediaType.POST] !== LoadingStates.LOADING;
      const isReelLoaded = publishingStatusesForAccount[PlatformMediaType.REELS] !== LoadingStates.LOADING;
      const isStoryLoaded = publishingStatusesForAccount[PlatformMediaType.STORIES] !== LoadingStates.LOADING;

      if (!isPostLoaded || !isReelLoaded || !isStoryLoaded) {
        return false;
      }
    }

    return true;
  });
};

export const useHideNextButton = (): boolean => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.hideNextButton;
  });
};

export const useNumParentAccountsConnected = (): number => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.numParentAccountsConnected;
  });
};

export const useWizardCaptionErrors = (): CaptionErrorByAccountIds => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.captionError;
  });
};

export const useWizardTitleErrors = (): TitleErrorByAccountIds => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.titleError;
  });
};

export const useWizardConnectedAccounts = (): SocialAccount[] => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.accounts;
  });
};

export const useWizardAccountsToPublishTo = (): number[] => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.accountIdsToPublishTo;
  });
};

export const useWizardPublishingAction = (): SocialPostPublishingAction | null => {
  return useAppSelector((state) => {
    if (state.socialMediaWizard.publishingAction) {
      return state.socialMediaWizard.publishingAction;
    }

    return null;
  });
};

export const useDesignIdsToPublish = (): PosterHashedId[] => {
  return useAppSelector((state): string[] => {
    return state.socialMediaWizard.designIdsToPublish;
  });
};

export const useWizardPublishingParams = (): Record<SocialMediaAccountId, MediaTypePublishingParams> => {
  return useAppSelector((state): Record<SocialMediaAccountId, MediaTypePublishingParams> => {
    return state.socialMediaWizard.publishingParams;
  });
};

export const useWizardDesignsGraphicsMeta = (): Record<PosterHashedId, GraphicMeta> => {
  return useAppSelector((state): Record<PosterHashedId, GraphicMeta> => {
    return state.socialMediaWizard.designsGraphicsMeta;
  });
};

export const useNeedsResizeMapForDesigns = (): AccountNeedsResizeMapForDesigns => {
  return useAppSelector((state): AccountNeedsResizeMapForDesigns => {
    return state.socialMediaWizard.accountNeedsResizeMapForDesigns;
  });
};

export const useAccountNeedsResizeMapForDesigns = (accountId: number | null): null | Record<PosterHashedId, PosterNeedsResizeInformation> => {
  return useAppSelector((state): null | Record<PosterHashedId, PosterNeedsResizeInformation> => {
    if (!accountId || !state.socialMediaWizard.accountNeedsResizeMapForDesigns || !state.socialMediaWizard.accountNeedsResizeMapForDesigns[accountId]) {
      return null;
    }

    return state.socialMediaWizard.accountNeedsResizeMapForDesigns[accountId];
  });
};

export const useIsPublishingVideoForAccount = (): boolean => {
  return useAppSelector((state): boolean => {
    const wizardDesignsGraphicsMeta = state.socialMediaWizard.designsGraphicsMeta;

    if (!wizardDesignsGraphicsMeta) {
      return false;
    }

    const posterIds = Object.keys(wizardDesignsGraphicsMeta);

    for (let i = 0; i < posterIds.length; i++) {
      if (wizardDesignsGraphicsMeta[posterIds[i]].isVideo) {
        return true;
      }
    }

    return false;
  });
};

export const useDesignSelectorTab = (): DesignSelectorTab => {
  return useAppSelector((state): DesignSelectorTab => {
    return state.socialMediaWizard.designSelectorTab;
  });
};

export const usePublishableURLsForAccount = (): AccountPublishableUrlsForMediaType => {
  return useAppSelector((state): AccountPublishableUrlsForMediaType => {
    return state.socialMediaWizard.publishableGraphicUrlsForAllMediaTypes;
  });
};

export const useDesignsGraphicsMetaForAccountWithMediaType = (accountId: number, platformMediaType: PlatformMediaType): Record<PosterHashedId, GraphicMeta> => {
  return useAppSelector((state): Record<PosterHashedId, GraphicMeta> => {
    const mediaTypePublishingParams = state.socialMediaWizard.publishingParams[accountId];

    const publishingParams = mediaTypePublishingParams[platformMediaType];

    if (!publishingParams || !publishingParams.graphicsMeta) {
      return state.socialMediaWizard.designsGraphicsMeta;
    }

    return publishingParams.graphicsMeta;
  });
};

export const useIsAnyPosterResizingForAccountWithMediaType = (accountId: number, platformMediaType: PlatformMediaType): boolean => {
  return useAppSelector((state): boolean => {
    const resizingStatusForPosters = state.socialMediaWizard.accountResizeStatusForMediaType[accountId][platformMediaType];
    for (const [posterId, resizeInformation] of Object.entries(resizingStatusForPosters)) {
      if (resizeInformation === AccountResizeStatus.RESIZING) {
        return true;
      }
    }

    return false;
  });
};

export const useIsAnyPosterResizingForAccount = (accountId: number): boolean => {
  return useAppSelector((state): boolean => {
    const resizingStatusForPosters = state.socialMediaWizard.accountResizeStatusForMediaType[accountId];
    const platformMediaTypes: PlatformMediaType[] = [PlatformMediaType.POST, PlatformMediaType.STORIES, PlatformMediaType.REELS];

    for (const platformMediaType of platformMediaTypes) {
      const resizingStatusForMediaType = resizingStatusForPosters[platformMediaType];
      if (
        Object.values(resizingStatusForMediaType).some((status) => {
          return status === AccountResizeStatus.RESIZING;
        })
      ) {
        return true;
      }
    }

    return false;
  });
};

const isResizeRequiredForPoster = (accountResizeRequiredStatus: PosterResizeRequiredStatus): boolean => {
  return accountResizeRequiredStatus === PosterResizeRequiredStatus.RESIZE_NEEDED;
};

export const useDoesAnyPosterRequireResizeForAccount = (accountId: number | null, platformMediaType: PlatformMediaType): boolean => {
  return useAppSelector((state): boolean => {
    if (!accountId) {
      return false;
    }

    const resizeRequiredForAccount = state.socialMediaWizard.accountNeedsResizeMapForDesigns[accountId];
    const publishingParams = state.socialMediaWizard.publishingParams[accountId];
    const {accounts} = state.socialMediaWizard;

    if (!publishingParams) {
      console.error('No publishing params for account with id: ', accountId, ' found');
      return false;
    }

    const specificAccount = accounts.find((account) => {
      return account.id === accountId;
    });

    if (specificAccount === undefined) {
      console.error('No account with id: ', accountId, ' found');
      return false;
    }

    if (!resizeRequiredForAccount) {
      return false;
    }

    const posterIds = Object.keys(resizeRequiredForAccount);

    for (let i = 0; i < posterIds.length; i++) {
      const resizeInfoForPoster = resizeRequiredForAccount[posterIds[i]];
      if (!resizeInfoForPoster) {
        console.error('No resize information found for poster');
        return false;
      }

      const accountNeedsResizeForPoster = resizeInfoForPoster.needsResizing;
      const reelNeedsResizeForPoster = resizeInfoForPoster.reelNeedsResizing;
      const storyNeedsResizeForPoster = resizeInfoForPoster.storyNeedsResizing;

      if (platformMediaType === PlatformMediaType.POST) {
        const isResizeNeeded = isResizeRequiredForPoster(accountNeedsResizeForPoster);

        if (isResizeNeeded) {
          return true;
        }
      }

      if (platformMediaType === PlatformMediaType.REELS) {
        if (reelNeedsResizeForPoster) {
          const isResizeNeeded = isResizeRequiredForPoster(reelNeedsResizeForPoster);

          if (isResizeNeeded) {
            return true;
          }
        }
      }

      if (platformMediaType === PlatformMediaType.STORIES) {
        if (storyNeedsResizeForPoster) {
          const isResizeNeeded = isResizeRequiredForPoster(storyNeedsResizeForPoster);

          if (isResizeNeeded) {
            return true;
          }
        }
      }
    }

    return false;
  });
};

export const useHasAnyPosterResizeErroredForAccount = (accountId: number, platformMediaType: PlatformMediaType): boolean => {
  return useAppSelector((state): boolean => {
    const resizingStatusForPosters = state.socialMediaWizard.accountResizeStatusForMediaType[accountId][platformMediaType || PlatformMediaType.POST];

    for (const [posterId, resizeInformation] of Object.entries(resizingStatusForPosters)) {
      if (resizeInformation === AccountResizeStatus.RESIZE_ERROR) {
        return true;
      }
    }

    return false;
  });
};

export const useIsAnyPosterLoadingForAccount = (accountId: number, platformMediaType: PlatformMediaType): boolean => {
  return useAppSelector((state): boolean => {
    const publishingParamsForAccount = state.socialMediaWizard.publishingParams[accountId];

    if (!publishingParamsForAccount) {
      return false;
    }

    const publishingParamsForPlatform = publishingParamsForAccount[platformMediaType];

    if (!publishingParamsForPlatform) {
      return false;
    }

    const designsGraphicMetaForAccount = publishingParamsForPlatform.graphicsMeta;

    if (!designsGraphicMetaForAccount) {
      console.error('No graphics meta found for account.');
      return false;
    }

    const designIds = Object.keys(designsGraphicMetaForAccount);

    for (let i = 0; i < designIds.length; i++) {
      const graphicMeta = designsGraphicMetaForAccount[designIds[i]];
      if (graphicMeta.graphicURL === '') {
        return true;
      }
    }

    return false;
  });
};

export const useAccountLevelErrorMessage = (): ErrorBoxInputs => {
  return useAppSelector((state): ErrorBoxInputs => {
    return state.socialMediaWizard.accountLevelErrorMessage;
  });
};

export const getPostId = (): string | null => {
  return useAppSelector((state) => {
    return state.socialMediaWizard.post?.id ?? null;
  });
};
